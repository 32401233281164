<template>
  <v-card :dark="$dark.get()">
    <div class="custom-card">
      <div class="custom-card-title header">
        <p>Список удаленных товаров</p>
        <div class="custom-card__input-conteiner">
          <v-text-field
            v-model="searchReqBody.name"
            label="Поиск..."
            class="custom-card__input"
            @keyup.enter="searchProducts()"
            @input="checkText($event)"
          >
            <v-btn
              v-if="searchReqBody.name"
              slot="append"
              color="red"
              icon
              @click="clearInput()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn slot="append" icon color="primary" @click="searchProducts()"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
          </v-text-field>
        </div>
      </div>

      <div class="custom-card__container">
        <div class="custom-table">
          <div class="head">
            <div style="width: 40px" class="head-item">
              <v-checkbox v-model="all" @change="setAllChecks()" />
            </div>
            <div style="width: 40px" class="head-item">
              {{ items.head[0].name }}
            </div>
            <div style="width: 200px" class="head-item">
              {{ items.head[1].name }}
            </div>
            <div style="width: 200px" class="head-item">
              {{ items.head[2].name }}
            </div>
            <div style="width: 100px" class="head-item">
              {{ items.head[3].name }}
            </div>
            <div style="width: 200px; text-align: right" class="head-item">
              {{ items.head[4].name }}
            </div>
            <div style="width: 150px; text-align: right" class="head-item">
              {{ items.head[5].name }}
            </div>
          </div>
          <div v-for="item in items.body" :key="item.id" class="value">
            <div style="width: 40px" class="value-item">
              <v-checkbox
                :value="checkboxState(item.providerProductId)"
                @click="addToList(item.providerProductId)"
              />
            </div>
            <div style="width: 40px" class="value-item">
              {{ item.providerProductId }}
            </div>
            <div style="width: 200px" class="value-item">
              {{ item.providerProductName }}
            </div>
            <div style="width: 200px" class="value-item">
              {{ normalizeDate(item.deleteTime) }}
            </div>
            <div style="width: 100px" class="value-item">
              {{ item.userName }}
            </div>
            <div style="width: 200px" class="value-item">
              {{ item.deleteReason }}
            </div>
            <div style="width: 150px" class="value-item">
              <v-btn
                color="primary"
                @click="restoreProduct(item.providerProductId)"
                >Восстановить</v-btn
              >
            </div>
          </div>
          <div style="margin-top: 10px">
            <v-pagination
              v-if="pages.count > 1"
              :value="pages.current + 1"
              :dark="$dark.get()"
              :total-visible="7"
              :length="pages.count"
              @input="changePage($event)"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="show"
      width="500"
      persistent
      :dark="$dark.get()"
      @click:outside="closeModal"
    >
      <v-form>
        <v-card class="text-center">
          <v-card-title>
            Вы собираетесь восстановить сразу несколько продуктов! Убедитесь,
            что продукты с нижеуказанными ID верны ?
          </v-card-title>
          <v-card-text
            class="mb-6 pt-8 form__text"
            style="font-size: 26px; line-height: 100%; overflow-y: scroll"
          >
            <p v-for="id in checkedListOfProducts" :key="id" class="form__id">
              {{ id }}
            </p>
          </v-card-text>
          <v-card-actions class="d-flex justify-center pb-6">
            <v-btn
              class="mr-12 pl-3 pr-3 text-capitalize"
              outlined
              color="#A4A4A4"
              @click="closeModal"
              >Нет</v-btn
            >
            <v-btn
              class="pl-3 pr-3 text-capitalize"
              color="error"
              @click="restoreCheckedProducts"
              >Да, точно</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>

<script>
import api from "@/api";
import showMessage from "@/Functions/message";

export default {
  data() {
    return {
      show: false,
      all: false,
      checkedListOfProducts: [],
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 3, name: "Название" },
          { id: 4, name: "Когда удален" },
          { id: 5, name: "Кем удален" },
          { id: 6, name: "Причина" },
          { id: 7, name: "Действие" },
        ],
        body: [{}],
      },
      pages: {},
      searchReqBody: {
        name: "",
        page: 0,
        size: 50,
      },
      deletedProductsResp: {},
    };
  },
  mounted() {
    this.getDeleted();
  },
  methods: {
    setAllChecks() {
      if (this.all) {
        this.items.body.forEach((element) => {
          if (!this.checkedListOfProducts.includes(element.providerProductId)) {
            this.checkedListOfProducts.push(element.providerProductId);
          }
        });
      } else {
        this.checkedListOfProducts = [];
      }
    },
    closeModal() {
      this.show = false;
    },
    addToList(id) {
      if (this.checkedListOfProducts.includes(id)) {
        let arr = [];
        for (let i = 0; i < this.checkedListOfProducts.length; i++) {
          if (id !== this.checkedListOfProducts[i]) {
            arr.push(this.checkedListOfProducts[i]);
          }
        }
        this.checkedListOfProducts = arr;
      } else {
        this.checkedListOfProducts.push(id);
      }
    },
    checkboxState(id) {
      let indexOfId = this.checkedListOfProducts.indexOf(id);
      if (indexOfId != -1) {
        return true;
      } else return false;
    },
    restoreCheckedProducts() {
      const response = api.DeletedProducts.restoreProducts(
        this.checkedListOfProducts
      );
      response.then(() => {
        showMessage(
          `Продукты ${this.checkedListOfProducts} успешно восстановлены!`,
          true
        );
        this.checkedListOfProducts = [];

        this.getDeleted(this.pages.current);
        this.closeModal();
      });
    },
    restoreProduct(item) {
      if (this.checkedListOfProducts.length === 0) {
        const response = api.DeletedProducts.restoreProducts([item]);
        response.then(() => {
          showMessage(`Продукт ${item} успешно восстановлен!`, true);
          if (this.findMode) {
            this.searchProducts();
          } else {
            this.getDeleted(this.pages.current);
          }
        });
      } else {
        this.show = true;
      }
    },
    changePage(page) {
      this.checkedListOfProducts = [];
      this.all = false;
      if (this.findMode) {
        this.searchReqBody.page = page - 1;
        this.searchProducts();
      } else {
        this.getDeleted(page - 1);
      }
    },
    setPages(data) {
      if (data) {
        this.pages = {
          count: data.totalPages,
          current: data.number,
          elements: data.totalElements,
        };
      }
      return this.pages;
    },
    async getDeleted(page = 0) {
      const response = await api.DeletedProducts.getDeletedProducts(page);
      this.deletedProductsResp = response;
      this.setPageData(response);
    },
    normalizeDate(str) {
      const date = new Date(str);
      return date.toLocaleString().slice(0, -3);
    },
    // устанавливает список всех товаров или найденных
    setPageData(data) {
      this.items.body = data.content;
      this.pages = this.setPages(data);
    },
    async searchProducts() {
      if (!this.searchReqBody.name) {
        return;
      }
      const response = await api.DeletedProducts.findDeletedProducts(
        this.searchReqBody
      );
      this.setPageData(response);
      this.findMode = true;
    },
    clearInput() {
      this.searchReqBody.name = "";
      this.searchReqBody.page = 0;
      this.findMode = false;
      this.setPageData(this.deletedProductsResp);
    },
    // если убрали весь текст из ввода возвращает исходные продукты
    checkText(text) {
      if (!text) {
        this.clearInput();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-card {
  padding: 40px;
  position: relative;
  &__input-conteiner {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .custom-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 24px;
  }
  .custom-card-btn {
    position: absolute;
    right: 40px;
    top: 40px;
  }
  .custom-card__container {
    .custom-table {
      .head {
        display: flex;
        justify-content: space-between;
        .head-item {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }
      }
      .value {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #cccccc;
        padding-bottom: 10px;
        font-size: 14px;
        &:last-child {
          border: none;
        }
        .value-item {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-top: 20px;
        }
      }
    }
  }
}
.form {
  &__text {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}
</style>
